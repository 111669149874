import HeadingCoex from "../assets/headingCoex";
import HeadingLogo from "../assets/headingLogo";
import ContentBlock, { ContentBlockProps } from "./content";
import Corridor from "./corridor";
import Footer, { FooterProps } from "./footer";

export type TemplateBlockProps = {
  content: ContentBlockProps;
  footer: FooterProps;
};

const TemplateBlock = ({
  footer,
  content,
}: TemplateBlockProps): JSX.Element => {
  return (
    <div className="relative min-w-full bg-primary">
      <a
        className="absolute top-4 left-4 w-[120px] animate-show opacity-30 sm:top-8 sm:left-8 sm:w-[150px]"
        href="/"
      >
        <img src="/logo.svg" alt="Containers For Change" />
      </a>
      <Corridor className="flex min-h-screen flex-col justify-end text-center">
        <div className="flex grow flex-col justify-center pt-44">
          <HeadingLogo />
          <HeadingCoex />
          <ContentBlock {...content} />
        </div>
        <div className="pt-12">
          <Footer {...footer} />
        </div>
      </Corridor>
    </div>
  );
};

export default TemplateBlock;
