import HeadingCoex from "../assets/headingCoex";
import HeadingLogo from "../assets/headingLogo";
import ContentBlock, { ContentBlockProps } from "./content";
import Corridor from "./corridor";

export type PageHeadProps = {
  content: ContentBlockProps;
};

const PageHead = ({ content }: PageHeadProps): JSX.Element => {
  return (
    <div className="relative h-[100vh] min-w-full  bg-primary sm:h-[90vh]">
      <a
        className="absolute top-4 left-4 w-[120px] animate-show opacity-0 sm:top-8 sm:left-8 sm:w-[150px]"
        href="/"
      >
        <img src="/logo.svg" alt="Containers For Change" />
      </a>
      <Corridor className="flex h-full flex-col justify-center text-center">
        <HeadingLogo />
        <HeadingCoex />
        <ContentBlock heading={content.heading} content={content.content} />
      </Corridor>
    </div>
  );
};

export default PageHead;
