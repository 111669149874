import classNames from "classnames";
import { FC } from "react";

export type CorridorProps = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * Slightly over-engineered corridor class that caps out at 1600px,
 * or at 1776px when the `wide` prop is set to `true`.
 */
const Corridor: FC<CorridorProps> = ({ className, children }) => {
  const classes = classNames(
    "mx-auto w-[calc(100%-theme(spacing.8))] max-w-[calc(theme(screens.xs)-theme(spacing.12))]",
    "2xs:w-[calc(100%-theme(spacing.12))] 2xs:max-w-[calc(theme(screens.sm)-theme(spacing.16))]",
    "sm:w-[calc(100%-theme(spacing.16))] sm:max-w-[calc(theme(screens.md)-theme(spacing.20))]",
    "md:w-[calc(100%-theme(spacing.96))] md:max-w-[calc(theme(screens.lg)-theme(spacing.96))]",
    "lg:w-[calc(100%-theme(spacing.96))] lg:max-w-[calc(theme(screens.2xl)-theme(spacing.96))]",
    "2xl:w-[calc(100%-theme(spacing.96)]",
    className,
  );
  return <div className={classes}>{children}</div>;
};

export default Corridor;
