import TemplateBlock from "../components/templateBlock";

const NotFound = (): JSX.Element => {
  return (
    <div>
      <TemplateBlock
        content={{
          heading: "404 Error",
          content: "Page not found!",
        }}
        footer={{
          email: "enquiries@containersforchange.com.au",
          phoneNumber: "13 42 42",
          themeWhite: true,
        }}
      />
    </div>
  );
};

export default NotFound;
