export type FooterProps = {
  phoneNumber: string;
  email: string;
  themeWhite?: boolean;
};

const Footer = ({
  themeWhite,
  phoneNumber,
  email,
}: FooterProps): JSX.Element => {
  return (
    <footer className="relative z-10 py-8 text-center">
      <img
        className={"mx-auto mb-6 aspect-square w-[100px] sm:mb-12"}
        src={themeWhite ? "/logo.svg" : "/coex-footer-logo.svg"}
        alt="COEX Footer Logo"
      />
      <a
        className={
          "mb-2 block font-asap text-body-md font-bold sm:text-[32px] " +
          (themeWhite ? "text-white" : "text-primary")
        }
        href={"tel:" + phoneNumber}
      >
        {phoneNumber}
      </a>
      <a
        className={
          "block font-arial text-body-sm font-bold " +
          (themeWhite ? "text-white" : "text-primary")
        }
        href={"mailto:" + email}
      >
        {email}
      </a>
    </footer>
  );
};

export default Footer;
