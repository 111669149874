import TemplateBlock from "../components/templateBlock";

const ThankYou = (): JSX.Element => {
  return (
    <div>
      <TemplateBlock
        content={{
          heading: "Thank you for your submission!",
          content:
            "Containers for Change will be in touch to arrange your collection.",
        }}
        footer={{
          email: "enquiries@containersforchange.com.au",
          phoneNumber: "13 42 42",
          themeWhite: true,
        }}
      />
    </div>
  );
};

export default ThankYou;
