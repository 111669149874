import Footer from "../components/footer";
import Form from "../components/form";
import PageHead from "../components/pageHead";

const Home = (): JSX.Element => {
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <PageHead
        content={{
          heading: "Book a collection for your building",
          content:
            "Book your collection and your Container Refund Point will  arrange a collection directly from your building.",
        }}
      />
      <div className="absolute top-[85.5vh] bottom-0 z-0 aspect-square w-[350vw] min-w-[3600px] max-w-[350vw] animate-slideInUp rounded-t-[80%] bg-white sm:top-[80.5vh]" />
      <Form />
      <Footer
        email="enquiries@containersforchange.com.au"
        phoneNumber="13 42 42"
      />
    </div>
  );
};

export default Home;
