import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

interface FormInput {
  firstName: string;
  lastName: string;
  mobile: number;
  email: string;
  schemeId: string;
  address1: string;
  address2: string;
  suburb: string;
  postcode: number;
  location: string;
  bins: number;
  description: string;
  descriptionOther: string;
  token: string;
}

const Form = (): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  //classes variable
  const classes = "w-full md:w-[calc(50%-1rem)] max-w-[100%]";
  const borderClasses =
    "border border-solid rounded-xl border-light h-[3.725rem] indent-3 outline-none focus:border-2 focus:border-primary w-full";
  const borderClassesWidth = classNames(borderClasses, classes);
  const heading = "text-body-base font-bold text-left mb-4 font-arial";
  const errorMessage = "ml-1 mt-1 text-red text-body-xs";

  //State
  const [descriptionSelect, setDescriptionSelect] = useState<string>();
  const handleOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDescriptionSelect(event.target.value);
  };

  //React Hook Form
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInput>({ mode: "onBlur" });

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    if (!executeRecaptcha) return;
    console.log(data);
    try {
      const token = await executeRecaptcha();
      console.log("recaptcha token:", token);
      const body = {
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        email: data.email,
        schemeId: data.schemeId,
        address1: data.address1,
        address2: data.address2,
        suburb: data.suburb,
        postcode: data.postcode,
        location: data.location,
        bins: data.bins,
        description: data.description,
        descriptionOther: data.descriptionOther,
        token,
      };
      const res = await fetch(process.env.REACT_APP_API_ENDPOINT, {
        method: "post",
        body: JSON.stringify(body),
      });
      if (res.ok) {
        // Show success message
        console.log("form submitted!");
        navigate("/thank-you");
      } else {
        // Show error message
        console.log("something went wrong");
      }
    } catch (err) {
      // Show error message
      console.log("something went wrong");
    }
  };

  return (
    <div className="relative z-10 w-full animate-slideInUp pb-16 sm:py-16 md:py-32">
      <div className="mx-auto w-[calc(100%-theme(spacing.8))] max-w-[990px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-12">
            <div>
              <h3 className={heading}>Enter you details</h3>
              <div className="flex flex-wrap justify-between gap-y-4">
                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("firstName", {
                      required: "First Name is required.",
                    })}
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>
                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("lastName", {
                      required: "Last Name is required.",
                    })}
                    placeholder="Last Name"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>

                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("mobile", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^(0|[0-9]\d*)(\.\d+)?$/,
                        message: "Must be numbers",
                      },
                    })}
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Mobile"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="mobile"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>

                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("email", {
                      required: "Please enter your email address",
                      pattern: {
                        value:
                          // eslint-disable-next-line no-useless-escape
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                    placeholder="Email"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>

                <div className={classes + " text-left"}>
                  <div className="flex h-[3.725rem] items-stretch">
                    <div className="flex w-1/6 rounded-l-xl bg-light text-center">
                      <span className="m-auto">C</span>
                    </div>
                    <input
                      className="w-5/6 rounded-r-xl  border border-solid border-light indent-3 outline-none focus:border-2 focus:border-primary"
                      {...register("schemeId", {
                        required: "Member Number is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Invalid Member Number. Must be numbers",
                        },
                        minLength: {
                          value: 8,
                          message: "Invalid Member Number. Must be 8 digits.",
                        },
                        maxLength: {
                          value: 8,
                          message: "Invaild Member Number. Must be 8 digits.",
                        },
                      })}
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Member Number"
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="schemeId"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                  <div className="mt-2 text-body-sm">
                    Don’t have a Member Number?{" "}
                    <a
                      className="text-primary hover:text-primary-700"
                      href="https://consumer.containersforchange.com.au/create-account?scheme=QLD"
                    >
                      Sign up here
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className={heading}>Your building address</h3>
              <div className="flex flex-wrap justify-between gap-y-4">
                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("address1", {
                      required: "Address is required",
                    })}
                    placeholder="Address Line 1"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address1"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>
                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("address2")}
                    placeholder="Address Line 2"
                  />
                </div>
                <div className={classes}>
                  <input
                    className={borderClasses}
                    {...register("suburb", {
                      required: "Suburb is required",
                    })}
                    placeholder="Suburb"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="suburb"
                    render={({ message }) => (
                      <p className={errorMessage}>{message}</p>
                    )}
                  />
                </div>
                <div
                  className={
                    classes +
                    " flex flex-wrap justify-between space-y-4 md:space-y-0"
                  }
                >
                  <div className={borderClassesWidth + " flex justify-start"}>
                    <span className="my-auto">QLD</span>
                  </div>
                  <div className={classes}>
                    <input
                      className={borderClasses}
                      {...register("postcode", {
                        required: "Postcode is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Invalid Postcode. Must be numbers",
                        },
                        minLength: {
                          value: 4,
                          message: "Invalid Postcode. Must be 4 digits.",
                        },
                        maxLength: {
                          value: 4,
                          message: "Invaild Postcode. Must be 4 digits.",
                        },
                      })}
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Postcode"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="postcode"
                      render={({ message }) => (
                        <p className={errorMessage}>{message}</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between gap-y-4">
              <div className={classes}>
                <h3 className={heading}>Number of bins to be collected</h3>
                <select
                  className={borderClasses + " w-full max-w-[100%]"}
                  {...register("bins", {
                    min: {
                      value: 1,
                      message: "Must be 1 bin or more",
                    },
                  })}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <ErrorMessage
                  errors={errors}
                  name="bins"
                  render={({ message }) => (
                    <p className={errorMessage}>{message}</p>
                  )}
                />
              </div>
              <div
                className={
                  classes + " flex flex-wrap justify-between gap-y-2 sm:gap-0"
                }
              >
                <p className="mb-4 w-full text-body-sm">Description of bins</p>
                <select
                  className={borderClassesWidth}
                  {...register("description")}
                  value={descriptionSelect}
                  onChange={handleOption}
                >
                  <option value="1201">120 litre bin</option>
                  <option value="2401">240 litre bin</option>
                  <option value="660">660 litre bin</option>
                  <option value="1100">1100 litre bin</option>
                  <option value="other">Other</option>
                </select>
                {descriptionSelect === "other" && (
                  <input
                    className={borderClassesWidth}
                    {...register("descriptionOther")}
                    placeholder="Provide description of Other"
                  />
                )}
              </div>
            </div>
            <div>
              <h3 className={heading}>Location of containers for collection</h3>
              <input
                className="h-[3.725rem] w-full rounded-xl border border-solid border-light indent-3 outline-none focus:border-2 focus:border-primary"
                type="text"
                {...register("location", {
                  required: "Location of containers for collection is required",
                })}
                placeholder="e.g. In carpark basement"
              />
              <ErrorMessage
                errors={errors}
                name="location"
                render={({ message }) => (
                  <p className={errorMessage}>{message}</p>
                )}
              />
            </div>
            <div>
              <h3 className={heading}>Collection refund</h3>
              <p className="w-full text-left md:w-[545px]">
                We’ll send your refund to the Member Number you have entered above.
                Please ensure you enter the Member Number nominated for your
                building.
              </p>
            </div>
          </div>
          <input
            className="mt-24 min-h-[68px] w-full cursor-pointer rounded-[10px] bg-primary py-3 font-asap text-[24px] font-bold uppercase text-white hover:bg-primary-700"
            type="submit"
            value="submit booking"
          />
        </form>
      </div>
    </div>
  );
};

export default Form;
