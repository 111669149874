export type ContentBlockProps = {
  heading: string;
  content: string;
};

const ContentBlock = ({ heading, content }: ContentBlockProps): JSX.Element => {
  return (
    <div>
      <h1 className="translate-y-8 animate-fadeUp py-8 font-asap text-mobile-body-lg font-bold text-white opacity-0 sm:text-body-lg">
        {heading}
      </h1>
      <div>
        <p className="mx-auto max-w-[600px] translate-y-8 animate-fadeUp text-body-sm text-white opacity-0 sm:text-body-md">
          {content}
        </p>
      </div>
    </div>
  );
};

export default ContentBlock;
