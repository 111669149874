const HeadingLogo = (): JSX.Element => {
  return (
    <div className="mx-auto h-auto w-[140px] translate-x-6 animate-moveForward sm:w-[200px]">
      <svg viewBox="0 0 202 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="fill-white"
          d="M193.596 0H76.2746C71.6804 0 67.9583 3.73276 67.9476 8.32694V27.3204H33.4701C30.3116 27.2885 27.4083 29.0752 26.0258 31.9146L17.3692 49.4618C16.7311 50.7699 15.9335 51.9928 14.9977 53.1095L5.26696 64.8076C2.10847 68.6148 0.385655 73.4217 0.396289 78.3668V96.4457C0.396289 102.231 5.08617 106.921 10.8714 106.931H22.7929C24.3243 95.9033 34.5016 88.2145 45.5298 89.7459C54.4629 90.9901 61.4818 98.009 62.7154 106.931H139.795C140.157 104.305 141.05 101.774 142.401 99.4872H75.4025V8.32694C75.4025 7.83774 75.796 7.44426 76.2852 7.44426H193.596C194.085 7.44426 194.479 7.83774 194.479 8.32694V98.5726C194.479 99.0618 194.085 99.4553 193.596 99.4553H177.112C178.463 101.742 179.356 104.273 179.718 106.9H193.596C198.19 106.9 201.912 103.167 201.923 98.5726V8.32694C201.923 3.73276 198.19 0.0106347 193.596 0V0ZM45.1788 62.5105H31.7792C29.1418 62.4999 27.0148 60.341 27.0255 57.7143C27.0255 56.9911 27.1956 56.2786 27.5253 55.6193L35.7034 39.0292C36.4797 37.4446 38.3939 36.8065 39.9785 37.5829C41.563 38.3592 42.2011 40.2734 41.4248 41.858L34.3847 56.1297H45.2107C46.9761 56.1297 48.4011 57.5548 48.4011 59.3201C48.4011 61.0855 46.9761 62.5105 45.2107 62.5105H45.1788Z"
          fill="white"
        />
        <path
          className="fill-white"
          d="M42.7541 124.596C50.9592 124.596 57.6107 117.944 57.6107 109.739C57.6107 101.534 50.9592 94.8824 42.7541 94.8824C34.549 94.8824 27.8975 101.534 27.8975 109.739C27.8975 117.944 34.549 124.596 42.7541 124.596Z"
        />
        <path
          className="fill-white"
          d="M159.757 124.596C167.962 124.596 174.613 117.944 174.613 109.739C174.613 101.534 167.962 94.8824 159.757 94.8824C151.551 94.8824 144.9 101.534 144.9 109.739C144.9 117.944 151.551 124.596 159.757 124.596Z"
        />
        <path
          className="origin-bottom animate-shaking fill-white"
          d="M103.754 80.1959L103.829 85.1517C103.871 87.7997 106.041 89.9266 108.689 89.9266H119.058C121.738 89.9266 123.918 87.7359 123.907 85.056C123.907 85.0453 123.907 85.0241 123.907 85.0134L123.843 80.1002C123.907 79.0155 123.322 77.9839 122.344 77.4947C123.344 76.963 123.939 75.9102 123.886 74.7829C123.96 73.5493 123.227 72.4007 122.067 71.9647C123.216 71.5287 123.95 70.4014 123.875 69.1678C123.939 67.9767 123.258 66.8707 122.174 66.3921C123.258 65.9136 123.939 64.8182 123.875 63.6271C123.96 62.3935 123.227 61.2556 122.078 60.8196C123.631 60.1177 124.311 58.2885 123.609 56.7359C123.301 56.0553 122.759 55.5129 122.078 55.2045C123.163 54.8004 123.886 53.7794 123.886 52.6203V50.1743C123.886 44.4316 121.036 39.5396 117.09 37.8275V35.8281C117.282 35.7005 117.399 35.4772 117.388 35.2432C117.388 35.0731 117.324 34.9029 117.218 34.7647C117.516 34.669 117.707 34.3925 117.707 34.0841V30.5108C117.707 30.128 117.409 29.8089 117.027 29.7983H110.646C110.263 29.8089 109.965 30.128 109.976 30.5108V34.116C109.976 34.4244 110.167 34.7009 110.465 34.7966C110.348 34.9348 110.284 35.1156 110.284 35.2964C110.274 35.5304 110.38 35.7643 110.582 35.892V37.8381C106.626 39.5715 103.776 44.4422 103.776 50.1849V52.6309C103.776 53.7794 104.488 54.8216 105.573 55.2151C104.02 55.917 103.34 57.7462 104.042 59.2988C104.35 59.9794 104.892 60.5218 105.573 60.8302C104.02 61.5427 103.34 63.3825 104.052 64.9352C104.339 65.5733 104.839 66.0837 105.467 66.4028C103.935 67.1685 103.308 69.0295 104.074 70.5716C104.382 71.1884 104.892 71.6882 105.52 71.986C104.361 72.4114 103.616 73.5599 103.701 74.7935C103.648 75.9208 104.254 76.9843 105.254 77.5054C104.307 78.069 103.733 79.0899 103.754 80.1959V80.1959Z"
        />
        <path
          className="origin-bottom animate-shaking fill-white"
          d="M143.422 89.0014C145.517 89.0014 147.218 87.3105 147.229 85.2155C147.229 85.2049 147.229 85.1942 147.229 85.1836L146.995 49.6532C147.112 47.1966 146.24 44.7932 144.56 42.9959C143.347 41.7517 143.347 41.422 143.315 38.221C143.103 32.9887 142.358 27.7884 141.071 22.705C141.199 22.5987 141.305 22.4817 141.39 22.3328C141.667 21.7798 141.699 21.1417 141.486 20.5674L141.252 19.8762H141.943L141.614 18.3235C141.422 17.5685 140.742 17.0367 139.965 17.0261H135.371C134.584 17.0261 133.904 17.5578 133.723 18.3235L133.383 19.8762H134.084L133.829 20.5462C133.617 21.1311 133.648 21.7798 133.925 22.3434C134.01 22.4817 134.116 22.6093 134.244 22.7156C132.947 27.7884 132.191 32.9887 131.979 38.221C131.979 41.4114 131.979 41.7623 130.735 43.0066C129.054 44.7932 128.161 47.1966 128.289 49.6532V85.3006C128.342 87.3637 130.043 89.0121 132.106 89.0014H143.422V89.0014Z"
        />
        <path
          className="origin-bottom animate-shaking fill-white"
          d="M170.094 47.8347C170.094 45.0484 162.788 44.9527 161.947 44.9527C161.107 44.9527 153.791 45.0165 153.791 47.8028C153.791 49.1002 153.599 49.3342 153.301 49.7276L153.11 49.9829C152.291 51.1314 151.844 52.5033 151.834 53.9071L151.674 85.726C151.664 87.5445 153.131 89.0333 154.95 89.044C154.95 89.044 154.96 89.044 154.971 89.044H169.253C171.072 89.044 172.55 87.5658 172.55 85.7472C172.55 85.726 172.55 85.7153 172.55 85.6941L172.018 53.939C172.018 52.5352 171.593 51.1633 170.774 50.0254L170.572 49.7702C170.274 49.3767 170.094 49.1321 170.094 47.8347"
        />
      </svg>
    </div>
  );
};

export default HeadingLogo;
